.license-container {
    padding: 20px;
    max-width: 900px;
    margin: auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background: rgb(238, 238, 238);
    margin-top: 20px;
}
  
.license-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
}
  
.license-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 30px;
    gap: 20px;
}
  
.license-image {
    flex-shrink: 0;
    width: 200px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}
  
.license-details {
    flex: 1;
}
  
.license-tour-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}
  
.license-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
}
  
.license-included {
    font-size: 0.9rem;
    color: #007bff;
    margin-bottom: 5px;
}
  
.license-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #28a745;
}

/* Responsive për Telefona */
@media (max-width: 768px) {
    .license-container {
        padding: 10px;
    }

    .license-title {
        font-size: 1.8rem;
    }

    .license-section {
        flex-direction: column; 
        align-items: center;
        text-align: center;
        padding: 10px;
    }

    .license-image {
        width: 70%; 
        height: auto; 
        margin-bottom: 10px; 
    }

    .license-tour-title {
        font-size: 1.5rem;
        text-align: center;
        padding: 10px;
    }

    .license-description,
    .license-included,
    .license-price {
        text-align: center; 
    }
}
