* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  transition: padding-left 0.3s ease;
}

/* Top Navbar Styles */
.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  z-index: 1000;
}

.main-content {
 
  padding: 20px;
}

/* Sections Styles */
.home, .services, .products, .sign-up {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/public/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/public/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/public/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* Sidebar Layout */
.app-layout {
  display: flex;
}

.sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #333;
  z-index: 1; 
}

.content {
  flex-grow: 1;
  background: #f4f4f4;
  z-index: 0;
  padding-left: 200px;
}

@media (max-width: 768px) {
  .top-navbar {
    position: relative;
    padding: 10px;
  }

  /* Mobile Layout Fixes */
  .main-content {
    /* margin-top: 60px; */
    padding: 15px;
  }

  .sidebar {
    display: none; /* Hide sidebar on mobile */
    width: 100%; /* Full width when displayed */
  }

  .hamburger {
    display: block; /* Show hamburger menu on mobile */
  }

  .mobile-menu {
    width: 100%;
    padding: 20px;
    right: 0;
  }

  /* Adjust font sizes for mobile */
  .home,
  .services,
  .products,
  .sign-up {
    font-size: 2rem; /* Smaller font size */
  }
  
  /* Mobile Navbar Toggle Styles */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .navbar .hamburger {
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .sidebar {
    width: 200px;
  }

  .top-navbar {
    padding: 15px;
  }
}
