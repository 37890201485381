
.photo-page-container {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
    padding: 50px 20px;
    max-width: 1200px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.photo-title {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
 
}

.photo-image-container {
    text-align: center;
    margin-bottom: 20px;
}

.photo-image {
    width: 100%;
    max-width: 1000px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.photo-description {
    font-size: 18px;
    color: #555;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 1.6;
}

.photo-link-container {
    text-align: center;
}

.photo-link {
    display: inline-block;
    font-size: 18px;
    padding: 15px 30px;
    background-color: #3dda71;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.photo-link:hover {
    background-color: #11683c;
    transform: translateY(-3px);
}

footer {
    margin-top: 50px;
    text-align: center;
    padding: 20px;
    background-color: #333;
    color: white;
    font-size: 16px;
}

@media (max-width: 768px) {
    .photo-page-container {
        padding: 30px 15px;
    }

    .photo-title {
        font-size: 28px;
    }

    .photo-description {
        font-size: 16px;
    }

    .photo-link {
        font-size: 16px;
        padding: 12px 25px;
    }
}
