/* Modal Background Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
    cursor: pointer;
}

/* Slider Container */
.modal-images {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
}

/* Slider Image */
.modal-images img {
    width: 45%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

/* Previous & Next Buttons */
.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    font-size: 1.5rem;
    border-radius: 50%;
    BACKGROUND: none;
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

/* Additional Styling for Text and Button */
h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.whatsapp-contact {
    margin-top: 2rem;
    text-align: center;
}

.whatsapp-contact a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: #25D366; /* WhatsApp green */
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.whatsapp-contact a:hover {
    background-color: #128C7E; /* Darker green when hovered */
}

.whatsapp-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
