.hotels-container {
    padding: 2rem;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #28a745; /* Ngjyra jeshile */
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.hotel-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    transition: all 0.3s ease;
}

/* Përshtatja për telefonët */
@media (max-width: 768px) {
    .hotel-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 kolona për ekrane më të vogla */
    }

    .section-title {
        font-size: 1.8rem; /* Ulja e madhësisë së titullit */
    }

    .hotel-title {
        font-size: 1.3rem; /* Përshtatja e madhësisë së titullit të hotelit */
        font-weight: bold;
        color: #333;
    }

    .hotel-country {
        font-size: 1rem; /* Ulja e madhësisë së tekstin për vendin */
        color: #777;
    }

    .hotel-description {
        font-size: 1rem; /* Ulja e madhësisë së përshkrimit */
        color: #555;
    }

    .learn-more-btn {
        padding: 0.5rem 1rem;
        background-color: #28a745; /* Ngjyra jeshile */
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s;
    }

    .learn-more-btn:hover {
        background-color: #218838; /* Ngjyrë më e errët për hover */
    }
}

/* Përshtatja për telefonët më të vegjël */
@media (max-width: 480px) {
    .hotel-grid {
        grid-template-columns: 1fr; /* Një kolonë për ekrane shumë të vogla */
    }

    .section-title {
        font-size: 1.5rem; /* Ulja e madhësisë së titullit për telefonët më të vegjël */
    }

    .hotel-title {
        font-size: 1.2rem; /* Ulja e madhësisë së titullit të hotelit */
    }

    .hotel-country {
        font-size: 0.9rem; /* Përshtatje e madhësisë së tekstin për vendin */
    }

    .hotel-description {
        font-size: 0.95rem; /* Përshtatje e madhësisë së përshkrimit */
    }

    .learn-more-btn {
        font-size: 0.95rem; /* Ulja e madhësisë së butonit për telefonët më të vegjël */
        padding: 0.4rem 0.8rem; /* Përshtatja e padding-ut të butonit */
    }
}

/* Stilimi i hotelit */
.hotel-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
    cursor: pointer;
}

.hotel-card:hover {
    transform: translateY(-5px); /* Lëvizje e lehtë kur kalon mbi hotel */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Ndikim i theksuar i hijes */
}

.hotel-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.hotel-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-top: 1rem;
}

.hotel-country {
    font-size: 1rem;
    color: #28a745; /* Ngjyra jeshile */
    margin-top: 0.5rem;
}

.hotel-description {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
}

.learn-more-btn {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #28a745; /* Ngjyra jeshile */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.learn-more-btn:hover {
    background-color: #218838; /* Ngjyrë më e errët për hover */
}
