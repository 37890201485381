.hero-video {
    object-fit: cover;
    width: 100%; 
    height: 60vh; 
    position: fixed; 
    top: 0;
    left: 0;
    z-index: -1; 
    background-color: black;
    opacity: 90%;
}
.hero-container {
    /* height: 50vh; */
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; 
    text-align: center;
    overflow: hidden; 
}
.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    z-index: 1;
    text-align: center;
    font-family: "Tangerine", cursive;
}

.hero-btns {
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    gap: 10px; 
    margin-top: 300px;
    position: relative; 
    z-index: 1; 
}

.hero-btns .btns {
    transition: transform 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.hero-btns .btns:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
    .hero-container {
        height: auto; 
        /* padding: 20px; */
        pointer-events: none; /* Heq mundësinë e klikimit mbi videon */
    }
    .hero-video {
        object-fit: cover;
        width: 100%; 
        height: 60vh; 
        position: fixed; 
        top: 0;
        left: 0;
        z-index: -1; 
        background-color: black;
        opacity: 90%;
       
    }
    
    .video {
        object-fit: contain; 
        height: auto;
    }
  
    .hero-btns {
        flex-direction: column; 
        /* margin-top: 100px; */
        gap: 15px; 
    }
    .hero-text {
        font-size: 6rem; 
    }

    .hero-btns .btns {
        width: 80%;
        margin-bottom: 10px;
    }
}
/* @media (max-width: 480px) {
    .hero-text {
        font-size: 3rem;
    }
} */