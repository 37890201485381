.footer-container {
    background: linear-gradient(to right, #2e7d32, #84af0fe6, #bdfd0e); /* Dark green to yellow-green to bright yellow */    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px 20px 0 0;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 2rem; /* Add spacing between columns */
    flex-wrap: wrap;
}

.footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
 
}

.footer-logo-img {
    width: 40px; /* Smaller logo */
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out;
}

.footer-logo-img:hover {
    transform: scale(1.1) rotate(5deg);
}

.footer-link-wrapper {
    flex: 1; /* Ensures equal width columns */
    min-width: 200px; /* Ensure columns don't get too small */
    margin-bottom: 20px; /* Add space below each column */
}

.footer-link-items {
    text-align: center;
}

.footer-link-items h2 {
    color: #f4f4f4;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.footer-link-items a {
    color: #d1d1d1;
    text-decoration: none;
    font-size: 1.2rem;
    margin: 5px 0;
    display: block;
    transition: color 0.3s ease;
}

.footer-link-items a:hover {
    color: #186be7;
}

.footer-copyright {
    text-align: center;
    font-size: 1rem;
    color: #d1d1d1;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
}

.footer-copyright a {
    color: #0bf098;
    text-decoration: none;
    font-weight: bold;
}

.footer-copyright a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack columns on small screens */
        align-items: center;
    }

    .footer-link-wrapper {
        width: 100%; /* Ensure columns take full width on mobile */
        margin-bottom: 1rem;
    }

    .footer-logo .travel-logo-img {
        height: 30px; /* Resize logo for mobile */
        width: auto; /* Maintain aspect ratio */
    }

    .footer-link-items a {
        font-size: 1rem; /* Adjust font size for mobile */
    }
}
