.cars-container {
    padding: 6rem 1rem;
    background: #f8f9fa;
    margin-top: 20px;
}

.section-title {
    text-align: center;
    font-family: "Tangerine", cursive;
    font-size: 70px;
    color: #343a40;
    margin-bottom: 40px;
    letter-spacing: 2px;
    text-transform: lowercase; /* E bën të gjithë tekstin të vogël */

   
}

.section-title::first-letter {
    text-transform: uppercase; /* E bën vetëm shkronjën e parë të madhe */
}

.car-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.car-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 1rem;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
}

.car-card:hover {
    transform: scale(1.05);
}

.car-title {
    font-size: 24px;
    color: #252e48;
}

.car-type {
    font-size: 18px;
    color: #6c757d;
}

.car-description {
    font-size: 16px;
    margin-top: 10px;
}

.learn-more-btn {
    margin-top: 15px;
    padding: 10px 20px;
    background: #30b37c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.learn-more-btn:hover {
    background: #0b462d;
}
