@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Lavishly+Yours&family=Tangerine:wght@400;700&display=swap');

.cards {
    padding: 6rem 1rem; 
    background: #f0f4f8;
    margin-top: 20px;
}

h1 {
    text-align: center;
    font-family: "Tangerine", cursive;
    font-size: 70px;
    color: #343a40;
    margin-bottom: 40px;
    letter-spacing: 2px;
}

.cards__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

.cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
}

.cards__items {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cards__item {
    display: flex;
    flex-direction: column; 
    flex: 1 1 300px;
    margin: 1rem;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s;
    height: 400px; 
}

.cards__item:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); 
}

.cards__item__link {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: rgba(31, 152, 244, 0.8);
    box-sizing: border-box;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%; 
    height: 100%; 
    object-fit: cover;
    transition: all 0.2s linear;
}

.cards__item__img:hover {
    transform: scale(1.1); 
}

.cards__item__info {
    padding: 20px 30px 30px;
    background-color: #fff; 
    flex-grow: 1; 
}

.cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: center; 
    margin-top: 10px; 
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
}

.modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    max-width: 800px;
    width: 100%;
    animation: fadeIn 0.3s ease-in-out;
}

.modal-content h2 {
    font-size: 24px;
    color: #343a40;
}

.modal-content p {
    font-size: 18px;
    color: #6c757d;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin: 0.5rem;
    }

    .cards__items {
        flex-direction: column;
        /* align-items: center; */
    }

    h1 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .cards__item {
        flex: 1 1 100%; 
    }

    h1 {
        font-size: 40px;
    }

    .modal-content {
        padding: 20px;
    }
}
