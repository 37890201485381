.features-container {
    text-align: center;
    margin: 40px auto;
    padding: 40px 20px; 
    backdrop-filter: blur(10px);
    border-radius: 10px; 
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.features-container h2 {
    font-size: 5em;
    color: #ffffff; 
    font-family: "Tangerine", cursive;
    margin-bottom: 20px;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 10px;
}

.feature-item {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.feature-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.feature-item .icon {
    font-size: 3em;
    margin-bottom: 15px;
    color: #4CAF50;
}

.feature-item h3 {
    font-family: 'Arial', sans-serif;
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
}

.feature-item p {
    font-family: 'Arial', sans-serif;
    color: #777;
}


@media screen and (max-width: 768px) {
    .features-grid {
        grid-template-columns: 1fr; 
    }

    .features-container h2 {
        font-size: 2.5em; 
    }

    .feature-item h3 {
        font-size: 1.3em; 
    }
}
