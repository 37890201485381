@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  background: linear-gradient(135deg, #e9ecef 0%, #f3f4f6 100%); 
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.header-text {
  font-size: 3rem; 
  color: #343a40;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.form-container:hover {
  transform: translateY(-5px); 
}

.form-text {
  width: 100%;
  margin-bottom: 20px;
}

.form-container p {
  font-size: 1rem;
  color: #6c757d;
  text-align: center;
  margin-bottom: 20px; 
}

.emailForm {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}

input[type="text"],
input[type="email"],
textarea {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid #0bf098; 
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

textarea {
  resize: vertical;
  min-height: 120px; 
}

button[type="submit"] {
  padding: 15px;
  background-color: #0bf098; 
  color: white;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

button[type="submit"]:hover {
  background-color: #005333; 
  transform: translateY(-2px); 
}

@media (max-width: 768px) {
  .header-text {
    font-size: 2.5rem;
  }

  .form-container {
    padding: 20px;
  }

  .form-container p {
    font-size: 0.9rem;
  }
}
