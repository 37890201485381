body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
}


.top-navbar {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    background: linear-gradient(to right, #2e7d32, #84af0fe6, #bdfd0e); /* Dark green to yellow-green to bright yellow */    display: flex;
    color: white;
    padding: 5px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: 50px;
  
}


.navbar-left {
    display: flex;
    align-items: center;
}

.navbar-logo {
    width: 100px;  
    height: auto;
    margin-left: -40px;
}


.navbar-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbar-right .translator {
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #fff;
    transition: border-color 0.3s ease-in-out;
}

.navbar-right .translator:hover {
    cursor: pointer;
    border-color: #00FF9C;
}


.contact-info {
    display: flex;
    gap: 20px;
    align-items: center;
}

.email-link, .location-link {
    color: white;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.email-link:hover, .location-link:hover {
    text-decoration: underline;
}

.email-link i, .location-link i {
    margin-right: 5px;
}


@media (max-width: 768px) {
    .top-navbar {
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 10px;
    }

    .navbar-left h1 {
        display: none;
    }

    .navbar-center .search-bar {
        width: 150px;
    }

    .navbar-right .translator {
        padding: 5px 10px;
    }

    .navbar-right {
        flex-wrap: nowrap;
        gap: 15px;
    }

    .contact-info {
        gap: 15px;
        margin-right: 0;
    }

    .email-link, .location-link {
        font-size: 12px;
    }
    .navbar-right {
        display: flex;
        align-items: center;
        
}
}