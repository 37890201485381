/* Containeri i faqeve të klinikës */
.clinic-page-container {
    text-align: center;
    margin: 50px auto;
    font-family: 'Arial', sans-serif;
    background-color: #f1f1f1; /* Ngjyrë të lehtë për background */
    padding: 60px 20px; /* Përdorim të padding të përshtatshëm */
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Titulli i klinikës */
.clinic-title {
    font-size: 2.5rem;
    color: #343a40;
    margin-bottom: 25px; /* Hapësirë më e madhe poshtë */
    font-weight: 600;
}

/* Pjesa e imazhit */
.clinic-image-container {
    margin-bottom: 40px; /* Hapësirë më e madhe poshtë imazhit */
}

.clinic-image {
    width: 100%;
    max-width: 1000px;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Hije më e theksuar */
}

/* Pjesa e përshkrimit të klinikës */
.clinic-description {
    margin-bottom: 30px;
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Kontejneri i lidhjes */
.clinic-link-container {
    margin-top: 30px;
}

/* Lidhja për "Visit Our Clinic" */
.clinic-link {
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
    background: linear-gradient(135deg, #6b7fd6, #a626e2); /* Gradient me ngjyra të freskëta */
    padding: 18px 35px;
    border-radius: 35px;
    display: inline-block;
    font-weight: 600;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 20px;
}

/* Efekti i kalimit mbi lidhjen */
.clinic-link:hover {
    background: linear-gradient(135deg, #315b88, #1f9d6d);
    transform: translateY(-4px); /* Efekti i ngritjes kur kalon mbi të */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    color: white;
}

/* Efekti pas klikimit */
.clinic-link:active {
    transform: translateY(0); /* Efekti i klikimit */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
