.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.modal-content {
    background-color: white;
    padding: 2rem;
    width: 80%;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}
.modal-images {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hotel-modal-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}
.hotel-modal-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 1rem;
    max-height: 500px;
}
.hotel-modal-country {
    font-size: 1.2rem;
    color: gray;
    margin-bottom: 1rem;
}
.hotel-modal-description {
    font-size: 1rem;
    margin-bottom: 1rem;
}
.hotel-modal-details {
    text-align: left;
}
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
}
.close-btn:hover {
    color: #007bff;
}
.whatsapp-contact {
    margin-top: 2rem;
    text-align: center;
}
.whatsapp-contact a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: #25D366;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s ease;
}
.whatsapp-contact a:hover {
    background-color: #128C7E;
}
.whatsapp-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}