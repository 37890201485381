.itineraries-container {
    padding: 40px;
    text-align: center;
    background-color: #f5f5f5;
}

.section-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
}

.itinerary-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 900px;
    margin: 0 auto;
}

.itinerary-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: calc(50% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.itinerary-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.itinerary-title {
    font-size: 1.5rem;
    color: #222;
    margin-bottom: 10px;
    font-family: Arial, sans-serif;
}

.itinerary-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
    font-family: Arial, sans-serif;
}

.learn-more-btn {
    background-color: #0bf098;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.learn-more-btn:hover {
    background-color:#055c3b;
}

@media (max-width: 768px) {
    .itinerary-card {
        width: 100%; 
    }

    .section-title {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 1.8rem;
    }
}
