.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    max-width: 800px;
    width: 100%;
    position: relative;
}


.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 30px;
    color: #343a40;
    cursor: pointer;
}


.close-btn:hover {
    color: #dc3545;

}


.modal-content h2 {
    font-size: 24px;
    color: #343a40;
}

.modal-content p {
    font-size: 18px;
    color: #6c757d;
}


.modal-images {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.modal-images img {
    /* width: 100px; */
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}