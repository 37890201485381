body {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Siguron që border dhe padding të mos ndikojnë në përmasat */
}

.wrapper {
  padding-top: 9px;
  margin-top: 50px;
  max-width: 100%;
  background: linear-gradient(to right, #2e7d32, #84af0fe6, #bdfd0e); /* Dark green to yellow-green to bright yellow */    display: flex;
 justify-content: center;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;  /* Mundëson rrotullim horizontal në pajisjet mobile */
}

.tabs-box {
  display: flex;
  gap: 12px;
  list-style: none;
  overflow-x: auto;  
  scroll-behavior: smooth;
  justify-content: flex-start;
}

.tabs-box .tab {
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;
  background: #f5f4fd;
  padding: 10px 18px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.tabs-box .tab:hover {
  background: linear-gradient(to right, #2e7d32, #84af0fe6, #bdfd0e); 

}

.tabs-box .tab.active {
  color: #fff;

  border-color: transparent;
 
}

@media (max-width: 768px) {
  .tabs-box {
      gap: 8px;
      width: 100%;
      flex-wrap: nowrap; /* Siguron që tabs të qëndrojnë në një rresht */
  }

  .tabs-box .tab {
      font-size: 0.9rem;
      padding: 4px 10px;
      border-radius: 15px;
      height: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .tabs-box::-webkit-scrollbar-thumb {
      background-color: #19965c;
      border-radius: 3px;
  }

  .tabs-box::-webkit-scrollbar-track {
      background-color: #f5f4fd;
  }
}
