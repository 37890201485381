:root {
    --primary: #fff;
    --btn-modern-border-color: #f0f0f0; 
    --btn-modern-bg-color: transparent; 
    --btn-modern-text-color: #f8f9fa; 
    --btn-modern-hover-bg-color: #f7f7f7;
    --btn-modern-hover-text-color: #fff; 
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #838282;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--modern {
    padding: 10px 20px;
    border: 2px solid var(--btn-modern-border-color);
    border-radius: 30px;
    background-color: var(--btn-modern-bg-color);
    color: var(--btn-modern-text-color);
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(241, 239, 239, 0.2), 0 6px 20px rgba(255, 253, 253, 0.19);
}

.btn--modern:hover {
    background-color: var(--btn-modern-hover-bg-color);
    color: var(--btn-modern-hover-text-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25), 0 12px 30px rgba(235, 231, 231, 0.15);
    transform: translateY(-2px);
}

/* Button Sizes */
.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
}

.btn--large:hover,
.btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
}
