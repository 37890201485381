/* About.css */

/* General styles for larger screens */
.about-container {
    padding: 4rem 2rem;
    background-color: #f5f5f5;
    color: #333;
    text-align: center;
}

.about-title {
    font-size: 2.5rem;
    color: #0d9919;
    margin-bottom: 1.5rem;
}

.about-description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 2rem;
}

.about-subtitle {
    font-size: 1.8rem;
    color: #006644;
    margin-top: 2rem;
}

.about-list {
    list-style: none;
    padding: 0;
    font-size: 1.1rem;
    color: #027a02;
}

.about-list li {
    margin: 0.5rem 0;
    text-align: left;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.about-list li strong {
    color: #008f37;
}

/* Styles for screens 768px and smaller (tablets and phones) */
@media (max-width: 768px) {
  .about-container {
    padding: 2rem 1rem;  /* Reduced padding for smaller screens */
  }

  .about-title {
    font-size: 2rem;  /* Smaller font size for titles */
    margin-bottom: 1rem;  /* Reduced space below title */
  }

  .about-description {
    font-size: 1rem;  /* Smaller font size for description */
    line-height: 1.5;  /* Slightly tighter line height */
    margin-bottom: 1.5rem;  /* Reduced bottom margin */
  }

  .about-subtitle {
    font-size: 1.6rem;  /* Smaller subtitle size */
    margin-top: 1.5rem;  /* Less margin at the top */
  }

  .about-list {
    font-size: 1rem;  /* Smaller font size for list items */
  }

  .about-list li {
    font-size: 1rem;  /* Slightly smaller list item text */
    margin: 0.3rem 0;  /* Reduced margin between items */
  }
}
